import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";


const DEFAULT_FORM_STATE = {
    particular: null,
    type: undefined,
    rate: null,
    c_gst: 0,
    s_gst: 0,
    _method: 'post',
};

const DEFAULT_FILTER_STATE = {
    particular: null,
    type: undefined,
    rate: null,
};

const COLUMN_DEFINATION = () => [
    {
        text: "#",
        sortable: false,
        value: 'id',
    },
    {
        text: "Particular",
        sortable: false,
        value: 'particular',
    },
    {
        text: "Package Type",
        sortable: false,
        value: 'type',
    },
    {
        text: "Rate",
        sortable: false,
        value: 'rate',
    },
    {
        text: "SGST",
        sortable: false,
        value: 's_gst',
    },
    {
        text: "CGST",
        sortable: false,
        value: 'c_gst',
    },
    {
        text: "Action",
        value: "action",
        sortable: false,
    },

];

export default {
    data() {
        return {
            operationTitle: 'Other Charges',
            formFields: {...DEFAULT_FORM_STATE},
            filters: {...DEFAULT_FILTER_STATE},
            listUrl: '/other/charges',
            columns: COLUMN_DEFINATION(this),
            formErrors: new Error({}),
        }
    },
    methods: {
        async handleSubmitOperation() {
            this.formErrors = new Error({})
            try {
                const response = await request({
                    url: this.formFields.id ? 'other/charges/update' : 'other/charges/create',
                    method: 'post',
                    data: this.formFields,
                })
                if (this.formFields.id) {
                    this.$global.itemUpdated()
                } else {
                    this.$global.itemAdded()
                }
                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }
                this.$global.handleServerError(error)
            }

        },
        async handleDeleteOperation(id) {
            let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
                primaryBtnText: 'Yes',
                secondaryBtnText: 'No'
            });
            if (deleteResponse) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/other/charges/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.$global.itemDeleted()
                } catch (errors) {
                    this.$global.handleServerError(errors)
                }
            }
        },
        async handleEditOperation(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/other/charges/detail/${id}`,
                })
                const {data} = response.data
                this.formFields = {
                    ...data,
                    property_id: data.property ? data.property.id : null
                }
            } catch (e) {
                this.$global.itemEditFails()
                this.formFields = {...DEFAULT_FORM_STATE}
            }
        },
        handleResetFilterClick() {
            this.filters = {...DEFAULT_FILTER_STATE}
            this.isFilterApplied = 'reset'
            this.checkFilterCountStatus()
        },
        handleOperationClose() {
            this.formFields = {...DEFAULT_FORM_STATE}
            this.$router.push({name: "otherChargeList"})
        },
    },
}
